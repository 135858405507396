.MissionStatement {
  @apply gap-y-0;
}

.shortRule {
  @apply my-10 w-1/6;
}

.intro {
  @apply flex flex-col gap-4;
}
