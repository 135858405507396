.Signpost {
  @apply relative;

  .imageContainer {
    width: 100%;
    min-height: 463px;
    aspect-ratio: 2.1;

    img {
      object-fit: cover;
    }
  }

  .content {
    @apply absolute text-left grid grid-cols-11 gap-y-2 gap-x-6;
    @apply px-6 md:px-[86px];
    @apply bottom-12 md:bottom-16;
  }

  .kicker {
    @apply col-span-12;
    color: var(--color-dark-content-secondary);
  }

  .headline,
  .description {
    color: var(--color-dark-content-primary);
  }

  .headline {
    @apply col-span-12;
  }

  .description {
    @apply col-span-12 sm:col-span-9 lg:col-span-6;
  }

  .cta {
    @apply mt-4 col-start-1 col-span-6;
  }
}
