.HomePage__Content {
  @media screen and (max-width: 1279px) {
    @apply mt-0;
  }
}

.HomePage__Signposts {
  /* offset the horizontal padding applied to Content, but only for signposts */
  @media screen and (max-width: 767px) {
    @apply -mx-6;
  }

  @media screen and (min-width: 768px) and (max-width: 1279px) {
    @apply -mx-12;
  }
}
