.grid {
  display: grid;

  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-gap: 3rem 1.5rem;

  @media screen and (min-width: 768px) {
    grid-gap: 3.5rem 1.5rem;
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-gap: 4.5rem 1.5rem;
  }

  @media screen and (min-width: 1280px) {
    grid-template-columns: repeat(14, minmax(0, 1fr));
  }
}

.col {
  grid-column: 1 / 7;
  align-self: start;

  @media screen and (min-width: 1024px) {
    grid-column: 1 / 13;
  }

  @media screen and (min-width: 1280px) {
    grid-column: 1 / 15;
  }
}

.super {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  @media screen and (min-width: 1024px) {
    margin-left: 0;
    margin-right: 0;
  }
}

.leftCol {
  @media screen and (min-width: 1024px) {
    grid-column: 1 / 6;
  }

  @media screen and (min-width: 1280px) {
    grid-column: 1 / 7;
  }
}

.rightCol {
  @media screen and (min-width: 1024px) {
    grid-column: 7 / 13;
  }

  @media screen and (min-width: 1280px) {
    grid-column: 8 / 15;
  }
}
