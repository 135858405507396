.tile {
  @apply border-b;

  &:last-child {
    @apply border-b-0;
  }

  /*
   * This border logic is wack, yo.
   */

  @screen sm {
    &:nth-child(even) {
      @apply border-l;
    }

    &:nth-last-child(2) {
      @apply border-b-0;
    }
  }

  @screen lg {
    &:nth-child(even) {
      @apply border-l-0;
    }

    &:nth-child(3n + 2) {
      @apply border-l border-r;
    }

    &:nth-last-child(2),
    &:nth-last-child(3) {
      @apply border-b-0;
    }
  }

  svg {
    @apply grayscale;
  }

  &:hover {
    svg {
      @apply opacity-100 grayscale-0;
    }
  }
}
